import TokenTimeline from 'components/issuerDashboard/TokenTimeline';
import {
  Container,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Card,
  Grid,
  CardContent,
  FormLabel,
  Button,
  Chip
} from '@mui/material';

import React, { useState, useEffect } from 'react';
import TokenTimelineOpenTxn from './TokenTimlineOpenTxn';
import { Avatar } from '@material-ui/core';

const InvestorStatus = (props) => {
  const [statusNumber, setStatusNumber] = useState(0);
  const [statusName, setStatusName] = useState('');
  const {
    issuerName,
    tokenValue,
    maturityDate,
    susbscribedqty,
    status,
    allotmentDate,
    previousAllotment,
    activeAllotment,
    tokenPrice
  } = props;

  useEffect(() => {
    if (status) {
      const statusName = status;
      setStatusName(statusName);
      switch (statusName) {
        case 'APPLIED':
          setStatusNumber(2);
          break;
        case 'ALLOTED':
          setStatusNumber(3);
          break;
        case 'PARTIALLY_REDEEMED':
          setStatusNumber(4);
          break;
        case 'REDEEMED':
          setStatusNumber(5);
          break;
        case 'FAILED':
          setStatusNumber(2);
          break;
        default:
          setStatusNumber(1);
      }
    }
  }, [status]);
  return (
    <>
      <Card sx={{ backgroundColor: '#ffffff', boxShadow: 'none', mb: 2 }}>
        <CardContent>
          <Grid container spacing={12}>
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: '#F2F2FF', boxShadow: 'none' }}>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    {/*  <Avatar
                      size="small"
                      sx={{ width: 24, height: 24 }}
                      src={`https://avatars.dicebear.com/api/gridy/${issuerName}.svg`}
                    /> */}
                    <Grid item lg={5} md={12} xs={12} sx={{ pl: 1, display: 'flex' }}>
                      <Typography sx={{ fontFamily: 'BeVietnamPro' }}>Issuer Name: </Typography>
                      <Typography sx={{ pl: 1, fontWeight: 'bold', fontFamily: 'BeVietnamPro' }}>
                        {issuerName}
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={12} xs={12} sx={{ pl: 3 }}>
                      <TokenTimelineOpenTxn statusNumber={statusNumber} />
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
              <Grid container spacing={2} sx={{ width: '100%', mt: 2, ml: 1 }}>
                {/* <Grid item lg={3} md={6} xs={12} sx={{ p: 1, display: 'flex' }}>
                  <Typography sx={{ color: '#8e8ea7' }}>Ongoing Allotment: </Typography>
                  <Typography sx={{ pl: 2 }}>{activeAllotment}</Typography>
                </Grid>
                <Grid item lg={3} md={6} xs={12} sx={{ p: 1, display: 'flex' }}>
                  <Typography sx={{ color: '#8e8ea7' }}>Prev. allotment: </Typography>
                  <Typography sx={{ pl: 2 }}>{previousAllotment}</Typography>
                </Grid> */}
                <Grid item lg={3} md={6} xs={12} sx={{ p: 1, display: 'flex' }}>
                  <Typography sx={{ fontFamily: 'BeVietnamPro' }}>Requested Qty: </Typography>
                  <Typography sx={{ pl: 2, fontFamily: 'BeVietnamPro' }}>
                    {susbscribedqty}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={6} xs={12} sx={{ p: 1, display: 'flex' }}>
                  <Typography sx={{ fontFamily: 'BeVietnamPro' }}>Token Price: </Typography>
                  <Typography sx={{ fontFamily: 'BeVietnamPro', pl: 2 }}>${tokenPrice}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default InvestorStatus;
