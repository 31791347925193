import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useBusinessEntityState, useSubscriptionState, useHttpApi, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import priceIcon from 'assets/images/price_vector.png';
import cryptoAssetTxn from '../../assets/images/masterdata-dashboard/cryptoAssetTxn.svg';
import { Ellipsis } from 'helpers/Ellipsis';
import tokenCardBackgroundImage from '../../assets/images/token_card_bg.png';
import btlLogo from '../../assets/images/bettor-token-logo.png';

const TokenSubCard = (props) => {
  const { setSubsToken, setToken, setCurrentTokenRuleDetails } = useBusinessEntityState();
  const { getRuleTemplateDetailedByToken } = useHttpApi();
  const { getSubscriberDetails } = useSubscriptionState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();

  // Function to split the float value into integer and decimal parts
  const formatFloatValue = (value) => {
    const floatValue = parseFloat(value);
    const integerPart = Math.floor(floatValue);
    const decimalPart = (floatValue - integerPart).toFixed(2).substring(2); // Extract decimal part

    return { integerPart, decimalPart };
  };

  const { integerPart, decimalPart } = formatFloatValue(props.token_issue_price);

  const CardFieldForPrice = ({ label, value }) => {
    return (
      <ListItemText
        sx={{
          mt: '16px',
          ml: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'left',
          alignItems: 'left'
        }}
        primary={label}
        secondary={value}
        primaryTypographyProps={{
          fontSize: '0.72rem',
          color: '#8a8a8a',
          textAlign: 'left',
          fontFamily: 'BeVietnamPro'
        }}
        secondaryTypographyProps={{
          fontSize: '23px',
          color: '#fff',
          textAlign: 'left',
          fontFamily: 'BeVietnamPro'
        }}
      />
    );
  };

  return (
    <Card
      elevation={10}
      sx={{
        display: 'flex',
        width: 600,
        height: 350,
        mt: 5,
        background: 'transparent',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '16px'
      }}
    >
      <Box
        component="img"
        src={tokenCardBackgroundImage}
        alt="Background"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Ensures the image covers the Card area
          zIndex: -1 // Places it behind the content
        }}
      />
      <CardContent sx={{ m: 3, p: 0, flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundImage: 'linear-gradient(to right, #1744F1, #121642)',
            width: '95%',
            margin: '0 auto',
            borderRadius: '8px',
            padding: '8px 16px'
          }}
        >
          <Typography
            sx={{
              m: 2,
              p: 0,
              fontWeight: 600,
              color: '#fff',
              fontFamily: 'BeVietnamPro'
            }}
            variant="h4"
          >
            {Ellipsis(props.token_symbol, '15rem')}
          </Typography>
          {/* <Box
            component="img"
            sx={{
              height: '2rem',
              mr: 2
            }}
            src={cryptoAssetTxn}
          /> */}
          <Box
            component="img"
            src={btlLogo}
            alt="Logo"
            sx={{
              width: '50px',
              zIndex: 1
            }}
          />
        </Box>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice label="Token Name" value={props.token_name} />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice
              label="Currency"
              value={Ellipsis(props.token_currency.currency_code)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice
              label="Issue Start Date"
              // value={props.token_start_date}
              value={new Intl.DateTimeFormat('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
              }).format(new Date(props.token_create_date))}
            />
          </Grid>
          {/* <Grid item lg={4} md={4} xs={4} sm={4}>
            <CardFieldForPrice
              label="Token isssue price (USD)"
              value={Ellipsis(props.token_issue_price)}
            />
          </Grid> */}
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice label="Token Price" value={`$${props.token_nav}`} />
          </Grid>
        </Grid>
        {/* <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <CardFieldForPrice label="Token NAV" value={props.token_nav} />
        </Box> */}
        <Grid
          container
          display="flex"
          justifyContent="left"
          alignItems="left"
          textAlign="left"
          marginTop={2}
          marginLeft={2}
        >
          <Grid item lg={4} md={4} xs={4} sm={4}>
            <Button
              size="medium"
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#007BFF'
              }}
              onClick={async () => {
                try {
                  setToken(props);
                  setSubsToken(props);
                  const response = await getRuleTemplateDetailedByToken(props.token_id);
                  setCurrentTokenRuleDetails(response);
                  navigate(`/subscription/view-token`);
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              More Info
            </Button>
          </Grid>
          <Grid item lg={4} md={4} xs={4} sm={4}>
            <Button
              variant="contained"
              size="medium"
              sx={{
                backgroundColor: '#007BFF',
                '&.Mui-disabled': {
                  backgroundColor: '#87b5e6',
                  color: '#3d4c5c'
                }
              }}
              disabled={!props.isKycDone}
              onClick={async () => {
                try {
                  navigate(`/subscription/form/${props.deployment_address}`);
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Invest
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TokenSubCard;
