import { Box, Card, CardContent, Container, Grid, Button } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import TokenActionsCard from 'components/issuerDashboard/TokenActionsCard';
import TokenTimeline from 'components/issuerDashboard/TokenTimeline';
import React, { useState, useEffect, useCallback } from 'react';
import ToggleCard, { ToggleBlockExplorer } from 'components/issuerDashboard/ToggleCard';
import PublishTokenDialog from 'components/CreateToken/PublishTokenDialog';
import allotments from 'assets/images/dashboard-icon/allotments.svg';
import redemptions from 'assets/images/dashboard-icon/redemptions.svg';
import cashTxn from 'assets/images/dashboard-icon/cashTxn.svg';
import KYCAMLCTF from 'assets/images/dashboard-icon/KYCAMLCTF.svg';
import docManagement from 'assets/images/dashboard-icon/docManagement.svg';
import escrowSettlement from 'assets/images/dashboard-icon/escrowSettlement.svg';
import auditReport from 'assets/images/dashboard-icon/auditReport.svg';
import newSubscription from 'assets/images/dashboard-icon/newSubscription.svg';
import {
  useBusinessEntityState,
  useHttpApi,
  useWeb3,
  useAuthStateShared,
  useAppState
} from 'state';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import RbacShowHideComponent from 'components/RbacShowHideComponent';
import TransactionHandler from 'components/TransactionHandler';
import { format, isBefore, isAfter, isEqual, startOfDay } from 'date-fns';
import LoadingActionButton from 'helpers/LoadingActionButton';
import BTLMintModal from 'components/issuerDashboard/BTLMintModal';
import BTLTransferModal from 'components/issuerDashboard/BTLTransferModal';
import BTLBurnModal from 'components/issuerDashboard/BTLBurnModal';
import enterpriseApp from 'assets/images/dashboard-icon/enterpriseApp.svg';
import eventIcon from 'assets/images/dashboard-icon/calendar.png';
import rolloverIcon from 'assets/images/dashboard-icon/process.png';

const TokenStatusStepper = ({
  status,
  tokenName,
  tokenSymbol,
  tokenTotalSupply,
  statusNumber,
  tokenId,
  tokenNav
}) => {
  const { publishToken } = useWeb3();

  const commitTransaction = useCallback(
    (hash) => {
      return publishToken(tokenSymbol, tokenName, Number(tokenTotalSupply), tokenNav);
    },
    [publishToken, tokenSymbol, tokenName, tokenTotalSupply, tokenNav]
  );

  const { fetchTokens, fetchCurrentToken } = useBusinessEntityState();
  const { getTokenDetailsById } = useHttpApi();

  const onConfirmed = useCallback(async () => {
    fetchTokens();
    const res = await getTokenDetailsById(tokenId);
    fetchCurrentToken(res);
  }, [fetchTokens, getTokenDetailsById, fetchCurrentToken, tokenId]);

  return (
    <TransactionHandler
      id={`token-status-${tokenSymbol}`}
      commitTransaction={commitTransaction}
      done={['PUBLISHED', 'ISSUED', 'REDEEMED', 'BURNT'].includes(status)}
      onConfirmed={onConfirmed}
    >
      {({ status: txStatus, error, receipt, send }) => {
        return <TokenTimeline statusNumber={statusNumber} />;
      }}
    </TransactionHandler>
  );
};

const BTLTokenDashboard = () => {
  // publish token dailog box
  const { finalizeIssuance, setNav } = useWeb3();
  const [totalSupply, setTotalSupply] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [openMintModal, setOpenMintModal] = useState(false);
  const [openBurnModal, setOpenBurnModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const today = new Date();

  const todayDate = format(today, 'yyyy-MM-dd');
  const {
    currentToken,
    fetchCurrentToken,
    investorsByIssuer,
    fetchInvestorsByIssuer,
    fetchAllInvestors,
    allInvestors
  } = useBusinessEntityState();

  const {
    setToken,
    setCurrentTokenById,
    setAllSubscriptionById,
    fetchWhiteListSubscriptions,
    fetchAllAllotments,
    fetchWhiteList,
    allSubscriptions,
    fetchIssuerInsurerMappingByIssuerId,
    whitelistedSubscriptions,
    fetchPendingDocCount,
    pendingDocsCount,
    fetchPendingKYCCount,
    pendingKYCCount,
    fetchAllReddemedAllotmentsByToken,
    reedemedTokens
  } = useBusinessEntityState();
  const { entity, user } = useAuthStateShared();
  const {
    getTokenById,
    getAllSubscriptionByToken,
    getTokenDetailsById,
    editToken,
    getRuleTemplateDetailedByToken,
    getOfflineFeesChargesTransactionBytokenId,
    getAllInvestorsByToken,
    getCanInitiateOrCompleteRollover,
    updateSubscriptionsOnRolloverComplete
  } = useHttpApi();
  const navigate = useNavigate();
  const {
    throwErrorMessage,
    fetchAllReadNotifications,
    tokenNotifications,
    fetchNotificationsByAddress
  } = useAppState();
  const [statusNumber, setStatusNumber] = useState(0);
  const [statusName, setStatusName] = useState('');
  const {
    account,
    pause: pauseBlockchain,
    unpause: unpauseBlockchain,
    getTotalSupply,
    getBalanceOf
  } = useWeb3();

  const [tokenSubscriptionCount, setTokenSubscriptionCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const [feesChargesCount, setFeesChargesCount] = useState(0);

  const fetchCurrentTokenSupply = async () => {
    const _totalSupply = await getTotalSupply(currentToken?.deployment_address);
    setTotalSupply(_totalSupply);
  };

  const fetchTokenBalance = async () => {
    const _balanceOf = await getBalanceOf(currentToken?.deployment_address);
    setTokenBalance(_balanceOf);
  };
  const isPause = async () => {
    try {
      if (currentToken.is_paused) {
        const res = await unpauseBlockchain(currentToken.deployment_address);
        console.log('unpause', res);
        if (res) {
          enqueueSnackbar('Token Submitted for Unpause', { variant: 'success' });
        }
      } else {
        const res = await pauseBlockchain(currentToken.deployment_address);
        console.log('pause', res);
        if (res) {
          enqueueSnackbar('Token Submitted for Pause', { variant: 'success' });
        }
      }
    } catch (e) {
      // enqueueSnackbar(
      //   'You are not authorised to Pause/unpause this token, Please use a issuer account',
      //   {
      //     variant: 'error'
      //   }
      // );
      throwErrorMessage(e);
      console.log(e);
    }
  };

  const updateToken = async (data) => {
    const res = await editToken(currentToken.token_id, data);
    if (res) {
      const res = await getTokenDetailsById(currentToken.token_id);
      fetchCurrentToken(res);
      enqueueSnackbar('Token Updated', { variant: 'success' });
    }
  };
  console.log(investorsByIssuer);

  const [canInitiateOrCompleteRolloverStatus, setCanInitiateOrCompleteRolloverStatus] = useState();
  const fetchRolloverButtonStatus = async () => {
    const canInitiateOrCompleteRolloverStatus_ = await getCanInitiateOrCompleteRollover();
    console.log('check status', canInitiateOrCompleteRolloverStatus);
    setCanInitiateOrCompleteRolloverStatus(canInitiateOrCompleteRolloverStatus_);
    return canInitiateOrCompleteRolloverStatus_;
  };
  useEffect(() => {
    fetchRolloverButtonStatus();
  }, [currentToken]);
  useEffect(() => {
    const fetchData = async () => {
      if (currentToken) {
        const tokenId = currentToken.token_id;
        const res = await getTokenDetailsById(tokenId);
        const fetch = fetchCurrentToken(res);
        await fetchAllReadNotifications();
        await fetchNotificationsByAddress();
        await fetchWhiteList();
        const _allSubs = await getAllSubscriptionByToken(currentToken.token_id);
        const investors = getAllInvestorsByToken(currentToken?.token_id);
        await fetchAllInvestors();
        setAllSubscriptionById(_allSubs);
        fetchWhiteListSubscriptions();
        setCustomerCount(investors.length ?? 0);
        await fetchAllReddemedAllotmentsByToken();
        const totalFees = await getOfflineFeesChargesTransactionBytokenId(tokenId);
        setFeesChargesCount(totalFees.length);
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTokenSubscriptionCount(whitelistedSubscriptions.length);
  }, [whitelistedSubscriptions]);

  useEffect(() => {
    fetchPendingDocCount(allSubscriptions);
    fetchPendingKYCCount(allSubscriptions);
  }, [allSubscriptions]);

  // Fetch Logged in IssuerId

  useEffect(() => {
    if (entity && entity.issuer_id) {
      fetchIssuerInsurerMappingByIssuerId(entity.issuer_id);
      // console.log({ issuerInvestorMapByIssuerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    if (currentToken.token_id) {
      const _statusName = currentToken.token_status_id.token_status;
      if (_statusName === 'NEW') {
        setToggleStatus(false);
      }
      setStatusName(_statusName);
      switch (_statusName) {
        case 'CREATED':
          setStatusNumber(1);
          break;
        case 'PUBLISHED':
          setStatusNumber(2);
          break;
        case 'ISSUED':
          setStatusNumber(3);
          break;
        case 'REDEEMED':
          setStatusNumber(4);
          break;
        case 'BURNT':
          setStatusNumber(5);
          break;
        default:
          setStatusNumber(1);
      }
    } else {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken]);
  console.log('🚀 ~ file: BTLTokenDashboard.js ~ line 294 ~ TokenDashboard ~ ̥', currentToken);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Container fixed>
      {/* Box to show the page title and breadcrumb component */}

      <Breadcrumbs aria-label="breadcrumb" pageHead={currentToken?.token_name} />

      {/* Starting a new container and dividing it into 2 parts of size 9 and 3 to fit the action card and timelines */}
      <Grid container spacing={3}>
        <Grid item lg={9} md={9} sm={9} sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Subscriptions"
                cardColor="#3672f7"
                count={tokenSubscriptionCount}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={newSubscription}
                  />
                }
                onClick={async () => {
                  try {
                    if (
                      isAfter(
                        startOfDay(new Date(currentToken.token_create_date)),
                        startOfDay(new Date())
                      )
                    ) {
                      enqueueSnackbar('Token Issue Date has not yet come', { variant: 'info' });
                      console.log(
                        'Token Issue Date has not yet come',
                        new Date(currentToken.token_create_date),
                        todayDate
                      );
                    } else {
                      await fetchWhiteListSubscriptions();
                      navigate('/home/token-dashboard/subscriptions');
                      console.log('Token Issue Date has come');
                    }
                  } catch (e) {
                    console.log('Error', e);
                  }
                }}
                linkName="/home/token-dashboard/subscriptions"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Allotments"
                cardColor="#fb7427"
                count={currentToken.active_allotments}
                onClick={async () => {
                  // await fetchAllAllotments();
                  navigate('/home/token-dashboard/view-allotment');
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={allotments}
                  />
                }
                linkName="/home/token-dashboard/view-allotment"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Redemptions"
                cardColor="#7465d9"
                count={currentToken.redeemed_allotments}
                onClick={async () => {
                  await fetchAllReddemedAllotmentsByToken();
                  navigate('/home/token-dashboard/view-redemption');
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={redemptions}
                  />
                }
                linkName="/home/token-dashboard/view-allotment"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Fees & Charges"
                cardColor="#c71492"
                count={feesChargesCount}
                onClick={async () => {
                  try {
                    navigate('/home/token-dashboard/fees-charges');
                  } catch (error) {
                    console.log(error);
                  }
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={cashTxn}
                  />
                }
                linkName="/home/token-dashboard/fees-charges"
              />
            </Grid>

            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Customer KYC & Bank Details"
                cardColor="#3edba5"
                count={currentToken.active_investors_count}
                onClick={async () => {
                  // await fetchInvestorsByIssuer();
                  navigate('/home/token-dashboard/customer-details');
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={KYCAMLCTF}
                  />
                }
                linkName="/home/token-dashboard/investors"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Reports"
                cardColor="#29c714"
                count={4}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={auditReport}
                  />
                }
                onClick={async () => {
                  // await fetchInvestorsByIssuer();
                  navigate('/home/token-dashboard/reports');
                }}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <TokenActionsCard
                title="Betting Events"
                cardColor="#c54a45"
                count={2}
                onClick={async () => {
                  navigate('/home/token-dashboard/betting-events');
                }}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={eventIcon}
                  />
                }
              />
            </Grid>
            {(!canInitiateOrCompleteRolloverStatus?.can_initiate ||
              !canInitiateOrCompleteRolloverStatus?.is_rollover_complete) && (
              <Grid item lg={3} md={4} sm={6}>
                <TokenActionsCard
                  title="Process Rollover"
                  cardColor="#d9a765"
                  count={currentToken.rollover_eligible_investors}
                  onClick={() => {
                    navigate('/home/token-dashboard/rollover');
                  }}
                  icon={
                    <Box
                      component="img"
                      sx={{
                        p: 1
                      }}
                      src={rolloverIcon}
                    />
                  }
                  linkName="/home/token-dashboard/rollover"
                />
              </Grid>
            )}

            {/* unused colors
            - #d9a765
            - #c54a45
            */}
          </Grid>
          <RbacShowHideComponent allowedRoles={['PRIMARY_ISSUER', 'ISSUER_OPERATOR', 'OWNER']}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item lg={12} md={9} sm={9}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end'
                  }}
                >
                  <LoadingActionButton
                    disabled={statusName === 'NEW' || user?.role === 'OWNER'}
                    loadingPosition="start"
                    variant="contained"
                    color="primary"
                    sx={{ width: '200px' }}
                    onClick={async () => {
                      console.log('checked', statusName);
                      if (statusName !== 'NEW') {
                        fetchCurrentTokenSupply();
                        fetchTokenBalance();
                        setOpenMintModal(true);
                      } else {
                        enqueueSnackbar('Token is not published yet!', {
                          variant: 'warning'
                        });
                      }
                    }}
                  >
                    Mint To Self
                  </LoadingActionButton>

                  <RbacShowHideComponent allowedRoles={['PRIMARY_ISSUER', 'OWNER']}>
                    <Button
                      variant="gradient"
                      disabled={statusName !== 'NEW' || user?.role === 'OWNER'}
                      sx={{ width: '200px', ml: 1 }}
                      onClick={async () => {
                        const _status = currentToken.token_status_id.token_status;

                        if (statusName === 'NEW') {
                          if (
                            isBefore(new Date(currentToken.token_start_date), todayDate) ||
                            isEqual(new Date(currentToken.token_start_date), todayDate)
                          ) {
                            console.log('This is the condition. ');
                            enqueueSnackbar('Cannot publish token after Issue Start Date', {
                              variant: 'warning'
                            });
                          } else {
                            setOpenPopup(true);
                          }
                        } else {
                          enqueueSnackbar('Token is already published', {
                            variant: 'warning'
                          });
                        }
                      }}
                    >
                      Publish
                    </Button>
                  </RbacShowHideComponent>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={statusName === 'NEW' || user?.role === 'OWNER'}
                    sx={{ width: '200px', ml: 1 }}
                    onClick={async () => {
                      if (statusName !== 'NEW') {
                        fetchTokenBalance();
                        setOpenTransferModal(true);
                      } else {
                        enqueueSnackbar('Token is not published yet!', {
                          variant: 'warning'
                        });
                      }
                    }}
                  >
                    Transfer
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    sx={{ width: '200px', ml: 1 }}
                    disabled={statusName === 'NEW' || user?.role === 'OWNER'}
                    onClick={async () => {
                      if (statusName !== 'NEW') {
                        fetchTokenBalance();
                        setOpenBurnModal(true);
                      } else {
                        enqueueSnackbar('Token is not published yet!', {
                          variant: 'warning'
                        });
                      }
                    }}
                  >
                    Burn
                  </Button>
                  <LoadingActionButton
                    variant="contained"
                    sx={{ width: 'auto', ml: 1 }}
                    loadingPosition="start"
                    // disabled={
                    //   statusName === 'NEW' ||
                    //   user?.role === 'OWNER' ||
                    //   (!canInitiateOrCompleteRolloverStatus?.can_initiate &&
                    //     !canInitiateOrCompleteRolloverStatus?.can_complete) ||
                    //   (canInitiateOrCompleteRolloverStatus?.is_rollover_complete &&
                    //   !canInitiateOrCompleteRolloverStatus?.can_complete)
                    // }
                    onClick={async () => {
                      try {
                        const res = await fetchRolloverButtonStatus();
                        console.log('TEST', res);
                        if (res?.can_initiate) {
                          if (res?.is_active_subscriptions_or_redemptions) {
                            enqueueSnackbar(res?.error_message, {
                              variant: 'error'
                            });
                            return;
                          }
                          // start the roll over process
                          const data = { rollover_ongoing: true };
                          await updateToken(data);
                        } else if (res?.can_complete) {
                          // complete the roll oger process
                          // const data = { rollover_ongoing: false };
                          // await updateToken(data);

                          const res = await setNav(
                            currentToken.deployment_address,
                            Math.round(Number(1).toFixed(5) * 100000)
                          );

                          enqueueSnackbar('Token Price update in progress', {
                            variant: 'success'
                          });
                          const res_ = await updateSubscriptionsOnRolloverComplete();
                          enqueueSnackbar(res_.message, { variant: 'success' });
                        } else {
                          if (currentToken.token_nav < 1) {
                            enqueueSnackbar(
                              'Cannot initiate this process as Token Price below 1.',
                              {
                                variant: 'error'
                              }
                            );
                            return;
                          } else if (!res?.can_initiate && res?.is_rollover_complete) {
                            enqueueSnackbar('Rollover already completed!.', {
                              variant: 'error'
                            });
                            return;
                          }
                          enqueueSnackbar(
                            'Rollover Ongoing. Please complete all the pending rollovers to process this action. ',
                            {
                              variant: 'error'
                            }
                          );
                        }
                        await fetchRolloverButtonStatus();
                      } catch (e) {
                        console.log(e);
                        throwErrorMessage(e);
                      }
                    }}
                  >
                    {canInitiateOrCompleteRolloverStatus?.can_initiate ||
                    canInitiateOrCompleteRolloverStatus?.is_rollover_complete ||
                    (canInitiateOrCompleteRolloverStatus?.is_rollover_complete &&
                      !canInitiateOrCompleteRolloverStatus?.can_initiate)
                      ? 'Initiate Rollover'
                      : 'Complete Rollover'}
                  </LoadingActionButton>
                </Box>
              </Grid>
            </Grid>
          </RbacShowHideComponent>
        </Grid>
        <Grid item lg={3} md={3} sm={3} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            // color="white"
            disabled={user?.role === 'OWNER'}
            sx={{ width: '100%', mb: 2 }}
            onClick={async () => {
              const response = await getTokenById(currentToken.token_id);
              console.log('get token response - ', response);
              setCurrentTokenById(response);

              navigate(`/home/token-dashboard/edit-token`);
            }}
          >
            View and Edit
          </Button>
          <Card>
            <CardContent>
              <TokenStatusStepper
                status={currentToken.token_status_id.token_status}
                tokenName={currentToken.token_name}
                tokenSymbol={currentToken.token_symbol}
                tokenTotalSupply={currentToken.total_supply}
                statusNumber={statusNumber}
                tokenId={currentToken.token_id}
                tokenNav={currentToken.token_nav}
              />
            </CardContent>
          </Card>
          <Box>
            {currentToken.deployment_address && (
              <ToggleBlockExplorer
                title="View on Block Scan"
                contractAdd={currentToken.deployment_address}
              />
            )}
            {currentToken.deployment_address && (
              <ToggleCard
                title="Pause"
                currPauseState={currentToken.is_paused}
                handleChange={isPause}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      {currentToken.token_id && (
        <>
          <PublishTokenDialog openPopup={openPopup} setOpenPopup={setOpenPopup} />
          <BTLMintModal
            open={openMintModal}
            setOpenModal={setOpenMintModal}
            totalSupply={totalSupply}
            tokenBalance={tokenBalance}
          />
          <BTLTransferModal
            open={openTransferModal}
            setOpenModal={setOpenTransferModal}
            tokenBalance={tokenBalance}
          />
          <BTLBurnModal
            open={openBurnModal}
            setOpenModal={setOpenBurnModal}
            tokenBalance={tokenBalance}
          />
        </>
      )}
    </Container>
  );
};

export default BTLTokenDashboard;
